<template>
  <v-card :style="[{ borderTop: `4px solid ${mainColor}` }]" style="max-width: 240px; width: 220px" class="pa-2">
    <v-card-title class="pa-0 pb-2">
      <div class="d-flex justify-space-between align-center" style="width: 100%;">
        <h4 class="main--text">{{ translations.changeStatus }}</h4>

        <v-btn @click="$emit('close')" fab width="18px" height="18px" color="main" depressed
          ><v-icon small color="white">{{ icons.mdiClose }}</v-icon></v-btn
        >
      </div>
    </v-card-title>

    <v-divider :style="[{ color: mainColor }, { height: '4px' }]"></v-divider>

    <v-card-text class="pa-0">
      <v-radio-group v-model="radios" @change="$emit('status-change', radios)" :disabled="canReadOnly">
        <v-radio v-for="status in statusList" :key="status.name" :value="status.id">
          <template v-slot:label>
            <div class="d-flex align-center justify-space-between" style="width: 100%">
              <div>
                {{ status.name }}
              </div>

              <div style="height: 20px; width: 20px" :style="[{ backgroundColor: status.color }]"></div>
            </div>
          </template>
        </v-radio>
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>

<script>
import { translations } from "@/utils/common";
import { mdiClose } from "@mdi/js";

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },

    statusList: {
      type: Array,
      required: true,
      default: () => []
    },

    currentStatus: {
      type: Number,
      required: true
    },

    canReadOnly: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({ radios: "" }),

  computed: {
    translations: () => translations,

    mainColor() {
      return this.$vuetify.theme.themes.light.main;
    },

    icons: () => ({ mdiClose })
  },

  watch: {
    currentStatus(newVal) {
      this.radios = newVal;
    },

    show(newVal) {
      if (newVal === false) {
        this.radios = "";
      }
    }
  }
};
</script>
